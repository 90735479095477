.appHeader-businness {
  position: sticky; /* Make it sticky */
  top: 0; /* Stick to the top */
  width: 100%;
  z-index: 9999;
  transition: background-color 0.3s ease, padding 0.3s ease,
    box-shadow 0.3s ease;
  padding: 20px 0; /* Default padding */
}

.appHeader-businness.scrolled-businness {
  padding: 5px 0; /* Reduce padding on scroll */
}

.headerTop-businness {
  display: flex;
  align-items: center;
  justify-content: right;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  color: black;
}

a {
  text-decoration: none;
  color: black;
}
.navbar-businness {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0px 4.5vw;
}
.navbar-left-businness {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
}
.navbar-listContainer-businness {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.navbar-list-businness {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0;
}
.navbar-item-businness {
  margin-right: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: space-between;
}

.navbar-item-businness a {
  display: inline;
}

.navbar-item-businness:hover {
  scale: 1.1;
  transition: 1s scale linear;
}

.navbar-sidebar-businness {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-sidebar-businness:hover {
  cursor: pointer;
}
.navbar-sidebar-drawer-businness {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 100vh;
  flex-direction: column;
}
.sidebar-drawer-item-businness {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  font-weight: bold;
  font-size: 1.17em;
  scale: 1;
}
.sidebar-drawer-item-businness:hover {
  scale: 1.1;
  transition: 0.5s scale linear;
}

/*MediaQuery pour afficher le Drawer*/
@media screen and (max-width: 800px) {
  .navbar-left-businness {
    display: none;
  }
  .navbar-sidebar-businness {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 100vw;
    margin-top: 10px;
  }
}

.logo-container-businness {
  max-width: 120px; /* Limite maximale de largeur */
  margin: 0 auto; /* Centre le conteneur si nécessaire */
  text-align: center; /* Optionnel pour l'alignement */
  overflow: hidden; /* Évite tout dépassement visuel */
}

@keyframes infiniteScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
